/* jshint esversion: 6 */

'use strict';

import * as Sentry from '@sentry/browser';
// Must import integrations second.
import {
    ExtraErrorData as ExtraErrorDataIntegration,
    CaptureConsole as CaptureConsoleIntegration,
    ReportingObserver as ReportingObserverIntegration,
    // Offline as OfflineIntegration, - Do not store any additional data in browser.
    SessionTiming as SessionTimingIntegration
    // TBD HttpClient integration (sends events on failed client-side HTTP requests).
} from '@sentry/integrations';
import { isSkipRecord, applyDynamicInitObject } from './sentry/utils';
import { getControllerUrl } from 'shared/js/url';
import { makeRequest } from 'shared/js/http';

const beaconRequestCallback = async (requestId) => {
    makeRequest(getControllerUrl('Beacon-Ping', {
        topic: 'sentry',
        rid: requestId
    }));
};

// Initialize Sentry browser agent.
// Usage of window is intentional.
if (window.SentryInitObject) {
    window.SentryInitObject.integrations = [
        // new BrowserTracing(getBrowserTracingOptions(window.SentryInitObject)),
        new ExtraErrorDataIntegration({
            depth: 2
        }),
        new CaptureConsoleIntegration({
            // Do not track console records other than "error".
            levels: [/* 'log', 'info', 'warn', 'debug', */'error']
        }),
        new ReportingObserverIntegration({
            types: ['crash', 'deprecation', 'intervention']
        }),
        // new OfflineIntegration({
        //     maxStoredEvents: 50
        // }),
        new SessionTimingIntegration()
    ];
    window.SentryInitObject.beforeBreadcrumb = (breadcrumb, hint) => {
        if (isSkipRecord(breadcrumb, hint)) {
            return null;
        }
        return breadcrumb;
    };
    applyDynamicInitObject();
    if (window.SentryInitObject.rId) {
        beaconRequestCallback(window.SentryInitObject.rId);
    }
    Sentry.init(window.SentryInitObject);
    window.Sentry = Sentry;
}
