'use strict';

const stringToRegExpPattern = new RegExp('^/(.*?)/([gimy]*)$'); // NOSONAR

/**
 * Skip specific events from being sent as breadcrumbs to Sentry.
 *
 * @param {Object} breadcrumb
 * @param {Object} hint
 * @return {boolean}
 */
function isSkipRecord(breadcrumb, hint) {
    // Skip Sentry logs.
    // noinspection RedundantIfStatementJS
    if (
        (
            (hint && hint.event && hint.event.logger === 'console')
            || (breadcrumb.category === 'console')
        ) && (
            breadcrumb.message && /^Sentry Logger/.test(breadcrumb.message)
        )
    ) {
        return true;
    }
    return (breadcrumb && breadcrumb.level && ['log', 'info', 'information'].indexOf(breadcrumb.level.toLowerCase()) > -1);
}

/**
 * Resolves the dynamic initialization scope.
 *
 * @return {string|undefined}
 */
function getInitializationCookie() {
    const name = 'public.sentry.initialScope';
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return undefined;
}

/**
 * Resolve configuration for the Integration > BrowserTracing.
 *
 * @param {Object} initializationObject
 * @return {Object}
 */
function getBrowserTracingOptions(initializationObject) {
    let result = {};
    if (!initializationObject) {
        return result;
    }

    // Initial setup - current domain.
    result.tracingOrigins = [window.location.hostname];
    // @see https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracingorigins

    if (Array.isArray(initializationObject.tracingOrigins)) {
        for (let value of initializationObject.tracingOrigins) {
            const match = value.match(stringToRegExpPattern);
            if (match && match.index >= 0) {
                result.tracingOrigins.push(new RegExp(match[1], match[2]));
            } else {
                result.tracingOrigins.push(value);
            }
        }
    }
    // @see https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#shouldcreatespanforrequest
    if (Array.isArray(initializationObject.tracingUrlExclusions)) {
        let tracingUrlExclusions = [];
        for (let value of initializationObject.tracingUrlExclusions) {
            const match = value.match(stringToRegExpPattern);
            if (match && match.index >= 0) {
                tracingUrlExclusions.push(new RegExp(match[1], match[2]));
            } else {
                tracingUrlExclusions.push(value);
            }
        }
        // Do not create spans for outgoing requests matching exclusion list.
        result.shouldCreateSpanForRequest = function (url) {
            return !tracingUrlExclusions.some(function (pattern) {
                if (pattern instanceof RegExp) {
                    return url.match(pattern);
                }
                return url.indexOf(pattern) > -1;
            });
        };
    }
    return result;
}

/**
 * Merges the dynamic initialization scope with the static one.
 *
 * @return {void}
 */
function applyDynamicInitObject() {
    const rawData = getInitializationCookie();
    let data = {};
    if (rawData) {
        try {
            data = typeof rawData === 'string' && rawData.length
                ? JSON.parse(atob(rawData))
                : {};
        } catch (exception) {
            // Invalid cookie value is provided, silently escape.
            /* eslint-disable no-console */
            console.log(exception);
            /* eslint-enable no-console */
        }
    }
    window.SentryInitObject.initialScope = {
        ...(window.SentryInitObject.initialScope || {}),
        ...data
    };
}

export {
    isSkipRecord,
    applyDynamicInitObject,
    getBrowserTracingOptions
};
